import "./hero-about.scss";

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import about from "../../assets/images/about.jpg";

const HeroAbout: React.FC = () => {
  const { t } = useTranslation("about");
  return (
    <div className={"o-hero o-hero--home o-hero--about"}>
      <div className="container">
        <div className={"m-hero-content m-hero-content--about"}>
          <section data-i18n="[html]content.body">
            <h1 className={"a-title a-title--hero a-title--hero--about"}>
              <Trans t={t}>hero.title</Trans>
            </h1>
            <p
              className={
                "a-paragraph a-paragraph--hero a-paragraph--hero--about is-c-light"
              }
            >
              {" "}
              {t("hero.desc")}
            </p>
          </section>
        </div>
      </div>

      <div className="container">
        <div className="flex-center p-bottom">
          <div className={"m-hero-detail"}>
            {/*<img src={about} loading={"lazy"} />*/}
            <StaticImage
              src={"../../assets/images/about.png"}
              alt={"sobre nos"}
            />
            <div className={"p-vertical-l"}>
              <p className={"a-quote"}>{t("hero.text")}</p>
              <p className={"a-sign"}>{t("hero.sign")}</p>
            </div>
            <h2 className="a-subtitle a-subtitle--hero is-text-center a-underline">
              {t("hero.subtitle")}
            </h2>
            <p
              className={
                "a-paragraph a-paragraph--hero is-text-center is-translucent"
              }
            >
              <Trans t={t}>hero.subtext</Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroAbout;
