import { Script, ScriptStrategy } from "gatsby";
import * as React from "react";

import {
  address,
  contact,
  logo,
  name,
  socialLinks,
  url,
} from "../../../data/config";

const HomeMarkupSchema: React.FC = () => {
  const markup = `{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "${name}",
  "alternateName": "C&R Seguridad",
  "url": "${url}",
  "logo": "${logo}",
  "contactPoint": {
    "@type": "ContactPoint",
    "email": "${contact.email}",
    "telephone": "${contact.phone}",
    "contactType": "customer service",
    "areaServed": "AR",
    "availableLanguage": "Spanish"
  },
  "sameAs": [
    "${socialLinks.facebook}",
    "${socialLinks.twitter}",
    "${socialLinks.linkedin}",
    "${socialLinks.instagram}",
    "${url}"
  ]
}`;
  const professionalServiceMarkup = `
{
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "name": "${name}",
  "image": "${logo}",
  "@id": "${url}",
  "url": "${url}",
  "telephone": "${contact.phone}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${address.streetAddress}",
    "addressLocality": "${address.city}",
    "postalCode": "${address.zipCode}",
    "addressCountry": "BR"
  },
  "department": [{
    "@type": "ProfessionalService",
    "name": "Custodia de mercadería",
    "url": "https://www.cyrseguridad.com.ar/custodia-mercaderia/",
    "image": "https://cdn.sanity.io/images/0ppj62c3/production/dda22a3cab855c1720a9d23a2e9f6ae9061bbeb7-1197x590.png"
  },{
    "@type": "ProfessionalService",
    "name": "Servicio de consultoría en seguridad",
    "url": "https://www.cyrseguridad.com.ar/consultoria-seguridad/",
    "image": "https://cdn.sanity.io/images/0ppj62c3/production/0d7d2318f93017833ee2330b1a01812a14bed3c7-1197x590.png" 
  },{
    "@type": "ProfessionalService",
    "name": "Servicio de recepción y mesa",
    "url": "https://www.cyrseguridad.com.ar/recepcion-y-mesa-de-entrada/",
    "image": "https://cdn.sanity.io/images/0ppj62c3/production/2de1e5b255c26d83a6852198a363c9329d8906e9-1197x590.png"
  },{
    "@type": "ProfessionalService",
    "name": "Servicio de seguridad física",
    "url": "https://www.cyrseguridad.com.ar/seguridad-fisica/",
    "image": "https://cdn.sanity.io/images/0ppj62c3/production/0ac48cfbf5c9c183c7ff79ba1ddeb5c4f016dfa5-1197x590.png"
  },{
    "@type": "ProfessionalService",
    "name": "Monitoreo y centro de operaciones",
    "url": "https://www.cyrseguridad.com.ar/monitoreo-centro-operaciones/",
    "image": "https://cdn.sanity.io/images/0ppj62c3/production/e9bc8a8cdeb24623a49796d8e23c585bcbe32882-1197x590.png"
  },{
    "@type": "ProfessionalService",
    "name": "Servicio supervisión y presentismo",
    "url": "https://www.cyrseguridad.com.ar/supervision-presentismo/",
    "image": "https://cdn.sanity.io/images/0ppj62c3/production/e936814d3a279db7c843c866488482b131fdd5f4-1197x590.png"
  }]
}
  	`;
  return (
    <>
      <Script type="application/ld+json" strategy={ScriptStrategy.postHydrate}>
        {markup}
      </Script>
      <Script type="application/ld+json" strategy={ScriptStrategy.postHydrate}>
        {professionalServiceMarkup}
      </Script>
    </>
  );
};

export default HomeMarkupSchema;
