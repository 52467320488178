import { SEO } from "components/common/SEO";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import About from "../components/about/about";
import Layout from "../components/common/layout/layout";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";
import OurPolitics from "../components/File/OurPolitics";
import Gallery from "../components/gallery/Gallery";
import HeroAbout from "../components/heroAbout/HeroAbout";

const AboutPage = () => {
  const { allSanityAbout: cards, sanityGallery: gallery } =
    useStaticQuery(graphql`
      query aboutandteam {
        allSanityAbout {
          nodes {
            title {
              pt_BR
              es_AR
            }
            desc {
              es_AR
              pt_BR
            }
            img {
              asset {
                id
                gatsbyImageData
                _id
              }
            }
          }
        }
        sanityGallery {
          gallery {
            asset {
              id
              _id
              gatsbyImageData
            }
          }
        }
        allSanityTeam {
          nodes {
            charge {
              es_AR
              pt_BR
            }
            name
            img {
              asset {
                id
                gatsbyImageData
                _id
              }
            }
          }
        }
      }
    `);
  return (
    <>
      <SEO
        title="Conoce C&R Seguridad - Sobre Nosotros"
        description="C&R Seguridad es la obvia elección en Argentina para soluciones de seguridad privada personalizadas y de alta calidad. Con sede central en Buenos Aires y bases en Mar del Plata, Bahía Blanca y la Ciudad de Mendoza."
        robots="index, follow"
        canonical={"sobre-nosotros/"}
      />
      <Layout>
        <HeroAbout />
        <OurPolitics />
        <div className="container">
          <About cards={cards.nodes} />
          {gallery && <Gallery photos={gallery.gallery} />}
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Conoce C&R Seguridad - Sobre Nosotros"
    description="Somos una empresa de seguridad que trabaja para estar a la vanguardia de la prestación de servicios de seguridad integral en la región, razón por la que nos esforzamos por operar de manera sostenible."
    robots="index, follow"
    canonical={"sobre-nosotros/"}
  />
);

export default AboutPage;
