import "./about.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const About: React.FC<any> = (props: { cards: any }) => {
  const { t } = useTranslation("about");

  const cards = [];
  props.cards.forEach((card) => {
    cards.push(
      <div>
        <div>
          <SanityImage
            {...card.img}
            width={350}
            alt={card.title.es_AR}
            className="a-img"
          />
        </div>
        <div>
          <p
            className={
              "a-title a-title--service a-title--service--about is-orange"
            }
          >
            {card.title.es_AR}
          </p>
          <p className={"a-desc a-desc--card text-justify"}>
            {card.desc.es_AR}
          </p>
        </div>
      </div>
    );
  });

  return (
    <section className={"o-about"}>
      <h2 className={"a-title a-title--body a-title--body--about"}>
        <Trans t={t}>content.title-cards</Trans>
      </h2>
      <div className={"container grid-x3"}>{cards}</div>
    </section>
  );
};

export default About;
