import "./gallery.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import React, { useState } from "react";

const Gallery: React.FC<any> = (props: { photos: any }) => {
  if (!props.photos || props.photos.length < 4) {
    return true;
  }
  const gallery = [];
  const [selectedPhoto, setSelectedPhoto] = useState(props.photos[0]);
  const [currentPosition, setCurrentPosition] = useState(0);
  const lenghtPhotos = props.photos.length - 1;

  function slidePhoto(isNext: boolean) {
    if (isNext && currentPosition >= lenghtPhotos) {
      setCurrentPosition(0);
      setSelectedPhoto(props.photos[currentPosition]);
      return;
    }
    if (!isNext && currentPosition <= 0) {
      setCurrentPosition(lenghtPhotos);
      setSelectedPhoto(props.photos[currentPosition]);
      return;
    }
    isNext
      ? setCurrentPosition(currentPosition + 1)
      : setCurrentPosition(currentPosition - 1);
    setSelectedPhoto(props.photos[currentPosition]);
  }
  function selectPhoto(index: number) {
    setCurrentPosition(index);
    setSelectedPhoto(props.photos[index]);
  }

  props.photos.forEach((photo, index) => {
    gallery.push(
      <SanityImage
        {...photo}
        width={250}
        key={index}
        alt={"foto"}
        onClick={() => selectPhoto(index)}
        className="a-gallery-photo"
      />
    );
  });
  return (
    <div className="container o-gallery">
      <section className={"m-gallery__selected"}>
        <SanityImage {...selectedPhoto} width={1280} alt={"selected photo"} />
        <div className="m-gallery__navigation">
          <span
            className={"a-button__gallery a-button__gallery--left"}
            onClick={() => {
              slidePhoto(false);
            }}
          >
            <svg
              width="57"
              height="57"
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.50">
                <path
                  d="M41 42.4286L25.375 28.5L41 14.5714L37.875 9L16 28.5L37.875 48L41 42.4286Z"
                  fill="black"
                />
              </g>
            </svg>
          </span>
          <span
            className={"a-button__gallery a-button__gallery--right"}
            onClick={() => {
              slidePhoto(true);
            }}
          >
            <svg
              width="57"
              height="57"
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.50">
                <path
                  d="M16 42.4286L31.625 28.5L16 14.5714L19.125 9L41 28.5L19.125 48L16 42.4286Z"
                  fill="black"
                />
              </g>
            </svg>
          </span>
        </div>
      </section>
      <div className={"m-gallery"}>{gallery}</div>
    </div>
  );
};

export default Gallery;
